import {Outlet, Navigate} from 'react-router-dom';

const PrivateRoutes = ({ children, ...rest }:any) => {

  let auth = { 'token': true };

  return (
    sessionStorage.getItem('token') ? <Outlet/> : <Navigate to='/login'/>
  );
}

export default PrivateRoutes;