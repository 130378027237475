import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpIcon from '@mui/icons-material/Help';
import CampaignIcon from '@mui/icons-material/Campaign';
import QuizIcon from '@mui/icons-material/Quiz';
import BugReportIcon from '@mui/icons-material/BugReport';
import { Link } from 'react-router-dom';

export const mainListItems = (
  <React.Fragment>
    <ListItemButton
      component={Link}
      to={"/dashboard"}
    >
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Tableau de bord" />
    </ListItemButton>
    <ListItemButton
    component={Link}
    to={"/annonce"}
    >
      <ListItemIcon>
        <CampaignIcon />
      </ListItemIcon>
      <ListItemText primary="Annonces" />
    </ListItemButton>
    <ListItemButton 
      component={Link}
      to={"/profil"}
    >
      <ListItemIcon>
        <AccountCircleIcon />
      </ListItemIcon>
      <ListItemText primary="Profil" />
    </ListItemButton>
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Aide
    </ListSubheader>
    <ListItemButton
      component={Link}
      to={"/callcenter"}
    >
      <ListItemIcon>
        <HelpIcon />
      </ListItemIcon>
      <ListItemText primary="Service Client" />
    </ListItemButton>
    <ListItemButton
      component={Link}
      to={"/faq"}
    >
      <ListItemIcon>
        <QuizIcon />
      </ListItemIcon>
      <ListItemText primary="F.A.Q" />
    </ListItemButton>
    <ListItemButton
      component={Link}
      to={"/report"}
    >
      <ListItemIcon>
        <BugReportIcon />
      </ListItemIcon>
      <ListItemText primary="Repporter une erreur" />
    </ListItemButton>
  </React.Fragment>
);