import React from 'react'
import DashboardContent from '../Dashboard/Content/Index';

export interface IHomePageProps{};

const Home: React.FunctionComponent<IHomePageProps>= (props:any) =>{
  return (
  <DashboardContent>     
    <div className="flex justify-center">
      <p>Tableau de bord</p>
    </div>
  </DashboardContent>
  )
}

export default Home;