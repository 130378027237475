import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from '../Common/Copyright';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import dayjs, { Dayjs } from 'dayjs';
import { postRequest } from '../redux/reducers/api';
import { Password } from '@mui/icons-material';


const theme = createTheme();


export default function SignUp() {
  
  
  const [registerDate, setRegisterDate] = React.useState<Dayjs | null>(dayjs('2022-04-07'));

  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    gender:'',
    password: '',
    token:''
  });

  const[confPwd, setConfPwd] = useState();

  
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      const res = await postRequest('/user/create', userData);
      console.log(res);
    } catch (error) {
      console.log("erreur lors de l'inscription d'un utilisateur.");
      console.log(error);
    }

    //console.log(userData);
    
    // console.log({
    //   email: data.get('email'),
    //   password: data.get('password'),
    // });
  };

  const handleChangeGender = (e: SelectChangeEvent) => {    
    setUserData({...userData, gender: e.target.value});    
  };

  const handleTextFieldChange = (value: string, key: string) => {
    setUserData({ ...userData, [key]: value });    
  };
    
  //useEffect(() => {console.log(gender)}, [gender])
   

  const handleOnChange = (e: any) => {    
    //setPhone(e.target.value);
    //console.log(phone);

  };

  //onclick on submit button 
  const handleClick = (e:any)=>{
    e.preventDefault()
    
      fetch("http://localhost:8080/user/add", {
        method:"POST",
        headers:{"content-Type":"application/json"},
        body:JSON.stringify(userData)
      }).then(()=>{
        //setSentMessage("nouveau utilisateur enregistré");
      })
      
  };
  
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <FormControl sx={{ m: 1, minWidth: 80 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputLabel id="select-label">Gender</InputLabel>
                  <Select
                    required
                    labelId="select-label"
                    id="select-autowidth"
                    value={userData.gender}
                    onChange={value => handleChangeGender(value)}
                    fullWidth
                    label="Gender"
                  >
                    <MenuItem value="">
                      <em>----</em>
                    </MenuItem>
                    <MenuItem value="homme">Homme</MenuItem>
                    <MenuItem value="femme">Femme</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                    value={userData.firstName}
                    onChange={value => handleTextFieldChange(value.target.value, 'firstName')}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                    value={userData.lastName}
                    onChange={value => handleTextFieldChange(value.target.value, 'lastName')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="phone"
                    label="Phone Number"
                    name="phone"
                    autoComplete="phone"
                    value={userData.phone}
                    onChange={value => handleTextFieldChange(value.target.value, 'phone')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={userData.email}
                    onChange={value => handleTextFieldChange(value.target.value, 'email')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    value={userData.password}
                    onChange={value => handleTextFieldChange(value.target.value, 'password')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="confirm-password"
                    label="Confirm Password"
                    type="password"
                    id="confirm-password"
                    autoComplete="confirm-password"
                    value={userData.password}
                    onChange={value => handleTextFieldChange(value.target.value, 'password')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox value="allowExtraEmails" color="primary" />}
                    label="I want to receive inspiration, marketing promotions and updates via email."
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="/login" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
              </FormControl>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
