import React, { useState } from 'react';
import {getAuth, GoogleAuthProvider, signInWithPopup} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';


export default function SignInWithFirebase() {

  const auth = getAuth();
  const navigate = useNavigate();
  const [authing, setAuthing] = useState(false);
  
  const signInWithGoogle =async () => {
    setAuthing(true);

    signInWithPopup(auth, new GoogleAuthProvider())
    .then(response=>{
      sessionStorage.setItem('token', response.user.uid);
      console.log("l'identifiant de l'utilisateur "+response.user.uid);
      navigate('/home');
    })
    .catch((error)=>{
      console.log(error);
      setAuthing(false);
    });
  }

  return (
    <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={()=>{signInWithGoogle()}} disabled={authing}
      >
      se connnecter avec google
    </Button>
  )
}
