export const config ={
  // My web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebaseConfig: {
    apiKey: "AIzaSyBSGMk4y50KGLJlYBp7M7Z6VQ7lllslsRk",
    authDomain: "retrouve-ton-objet.firebaseapp.com",
    projectId: "retrouve-ton-objet",
    storageBucket: "retrouve-ton-objet.appspot.com",
    messagingSenderId: "674144258552",
    appId: "1:674144258552:web:d3dac1458d382647c02e0d",
    measurementId: "G-V5K89Q8CY9"
  }
};