import React from 'react'
import DashboardContent from '../../Dashboard/Content/Index'

export default function Index() {
  return (
    <DashboardContent>
      <div className="flex justify-center">Profil template will be here</div>
    </DashboardContent>
  )
}
