import axios from 'axios';

const BACKEND_URL = 'http://localhost:8080/api/v1';
// const BACKEND_URL = 'https://7e97ab4f9940.ngrok.io/api/v1';
const http = axios.create({
  baseURL: BACKEND_URL,
  headers: {
    'Authorization': 'JWT ',
    'access-control-allow-origin': '*',
    'content-Type': 'application/json'
  }
});

// http.interceptors.request.use(async request => {
//   request.headers['Authorization'] = 'JWT';
//   return request;
// });

export default http;
