import { initializeApp } from "firebase/app";
import { config } from './config/config';
import {
  BrowserRouter as Router,
  Route,Routes,
} from "react-router-dom";
import Login from './Components/Auth/Login';
import Home from './Components/Pages/Home';
import UsersList from './admin/User/UsersList';
import SignUp from './Components/Auth/SignUp';
import Profile from "./Components/Pages/Profile/Index"
import PrivateRoutes from "./Components/utils/PrivateRoutes";
import Posts from "./Components/Pages/Posts";
import AddPosts from "./Components/Pages/Posts/AddPosts/Index"
import Post from "./Components/Pages/Posts/Post/Index"
import PostsList from "./Components/Pages/Posts/PostsList/Index"

// Initialize Firebase
const app = initializeApp(config.firebaseConfig);

function App() {
  return (
    <Router> 
      <Routes>
          <Route path="/login" element={<Login/>} />
          <Route path="/signup" element={<SignUp/>} />
          <Route element={<PrivateRoutes/>}>
            <Route  element={<UsersList/>} path="/utilisateurs" />
            <Route path="/" element={<Home/>} />
            <Route path="/dashboard" element={<Home/>} />
            <Route path="/annonce" element={<Posts/>} />
            <Route path="/profil" element={<Profile/>} />
            <Route path="/annonce/add" element={<AddPosts/>} />
            <Route path="/annonce/post" element={<Post/>} />
            <Route path="/annonce/list" element={<PostsList/>} />

          </Route>          
      </Routes> 
    </Router>
  );
}

export default App;
