import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { GpsFixed, Search, Window } from '@mui/icons-material';
import { useRef } from 'react';
import { autocompleteClasses } from '@mui/material';


const ApiKey = process.env.GOOGLE_MAPS_API;
const GoogleMapsApi = 'https://maps.googleapis.com/maps/api/js';

//load google maps api js

function loadAsyncScript (src: any){
  return new Promise(resolve => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src
    })
    script.addEventListener("load", ()=>resolve(script));
    document.head.appendChild(script);
  })
};

const extractAddress = (place:any) =>{
  if(!place.address_components){
    return "";
  }
  

}


export default function Index() {

  const searchInput = useRef(null);



//init google map script
const initMapScript = ()=>{
  //if script olready loaded
  if((window as any).google){
    return Promise.resolve();
  }
  const src = `${GoogleMapsApi}?key=${ApiKey}&libraries=places&callback=initMap`;
  return loadAsyncScript(src);
}


//init autocomplete
const initAutocomplete = () =>{
  if(!searchInput.current){
    return;
  }

  const autocomplete = new (window as any).google.maps.places.Autocomplete(searchInput.current);
  autocomplete.setFields(["address_component", "geometry"]);
  autocomplete.addListener("place_changed", ()=> onChangeAddress(autocomplete));
}

//do something on address change
const onChangeAddress = (autocomplete:any) =>{
  const location = autocomplete.getPlace();
}

//load map script after mounted
  React.useEffect(() => {
    initMapScript()
    .then(()=>initAutocomplete());
  }, [])
  
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 3, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      
      <TextField  label="Code Postal" variant="outlined" />
      <TextField  label="Ville" variant="outlined" />
      <TextField  label="Rue" variant="outlined" inputProps={<GpsFixed/>}/>
      <div className='search'>
        <span><Search/></span>
        <input type="text" ref={searchInput} placeholder="search adresse" />
        <button><GpsFixed/></button>
      </div>
      
    </Box>
  );
}
