import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Card, Container } from '@mui/material';
import { redirect, useNavigate } from 'react-router-dom';


export default function UsersList() {

  const [users, setUsers] = useState([]);
  const [isNotConnected, setIsNotConnected] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if(sessionStorage.getItem('token')){
    axios.get("http://localhost:8080/user/show")
      .then(res=>{
        console.log(res.data);
        setUsers(res.data);         
      })
      .catch(error =>{
        console.log(error.response);
      })
    }else{
      navigate('/login');
      setIsNotConnected(true);
    };
  }, [isNotConnected]);
  
  return (
      <Container>
        <div>La liste des utilisateurs</div>
        
          {users.map(
            (user:any)=>(
              <Card
                elevation={6} style={{margin:"20px", padding:"20px", textAlign:"left" }} key={user.id}>
                Id:{user.id}<br/>
                Nom:{user.lastName}<br/>
                Prenom:{user.firstName}<br/>
              </Card>
            )
          )}
    </Container>
  )
}
