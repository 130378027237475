import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import DashboardContent from '../../../Dashboard/Content/Index';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

export default function Index() {
  return (
    <DashboardContent>
    <Paper
      sx={{
        p: 2,
        margin: 'auto',
        maxWidth: 500,
        flexGrow: 1,
        marginTop: 3,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      }}
    >
      <Grid item className='flex justify-center'>
        <Stack spacing={2} direction="row">
          <Button variant="outlined" component={Link} to="/annonce/add">Supprimer</Button>
          <Button variant="outlined" component={Link} to="/annonce/add">Marquer retrouvé</Button>
        </Stack>              
      </Grid>
      <Grid container spacing={2}>
        <Grid item>
          <ButtonBase sx={{ width: 128, height: 128 }}>
            <Img alt="complex" src={'/public/key.jpg'} />
          </ButtonBase>
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography gutterBottom variant="subtitle1" component="div">
                Objet Perdu
              </Typography>
              <Typography variant="body2" gutterBottom>
                Clé automatique de voiture nissan qashqai
              </Typography>
              <Typography variant="body2" color="text.secondary">
                ID: 1030114
              </Typography>
            </Grid>
            
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" component="div">
              En cours ...
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
    </DashboardContent>
  );
}