import React from 'react';
import { Button, Container, Grid, List, ListItem, ListItemText, ListSubheader, Paper, Stack, styled } from '@mui/material';
import PostsList from './PostsList/Index'
import DashboardContent from '../../Dashboard/Content/Index';
import { Link } from 'react-router-dom';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Index() {
  return (
    <DashboardContent>
      <Container >
        <Grid container spacing={2} mb-4>
          <Grid className='flex justify-center' item xs={12} md={12}>
            <Stack spacing={2} direction="row">
              <Button variant="outlined" component={Link} to="/annonce/add">Faire une annonce</Button>
              <Button variant="outlined" component={Link} to="/annonce/list">Voir les annonces</Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </DashboardContent>
  )
}
