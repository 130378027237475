import React from 'react';
import { Container, Grid, Paper, styled } from '@mui/material';
import Status from '../status';
import Address from '../address';
import DashboardContent from '../../../Dashboard/Content/Index';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Index() {
  return (
    <DashboardContent>
      <Container >
        <Grid container spacing={2}>
          <Status/>
        </Grid>
        <Grid container spacing={2}>
          <Address/>
        </Grid>
        <Grid container spacing={2}>

          <Grid item xs={6} md={4}>
            <Item>xs=6 md=8</Item>
          </Grid>
          <Grid item xs={6} md={8}>
            <Item>xs=6 md=4</Item>
          </Grid>

        </Grid>
      </Container>
    </DashboardContent>
  )
}
